<template>
  <v-container>
    <v-row v-if="isPageLoading">
      <spinner style="margin: 300px 500px;"></spinner>
    </v-row>

    <div class="d-flex flex-column flex-grow-1" v-else>
      <div class="d-flex flex-row py-1">
        <PageTitle
          text="Access Control List"
          class=" font font-weight-bold font-size-lg"
        />
      </div>
      <div class="d-flex flex-row mb-2">
        <v-btn
          :to="{
            name: 'master.roles.add',
            query: { action: 'add' }
          }"
          class="font font-weight-medium font-size-md "
          color="primary"
        >
          Add New Role <i class="material-icons-outlined">add</i>
        </v-btn>
      </div>
      <div class="d-flex flex-row flex-grow-1 flex-wrap">
        <div class="d-flex flex-column flex-grow-1">
          <v-data-table
            class=" font font-weight-medium font-size-md box-shadow-light ma-1"
            :headers="headers"
            :items="roles"
            :footer-props="{
              itemsPerPageOptions: [15, 20, 30]
            }"
          >
            <template #item.actions="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :to="{
                      name: 'master.roles.add',
                      query: { action: 'edit', id: item.id }
                    }"
                    v-on="on"
                    icon
                    :id="item.id"
                  >
                    <i
                      class="material-icons-outlined font-size-md primary--text"
                    >
                      edit
                    </i>
                  </v-btn>
                </template>
                <span class="font font-weight-medium font-size-sm"
                  >Edit Role</span
                >
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click.stop="open('alert', 'acl/details', item.id)"
                    icon
                    :id="item.id"
                  >
                    <i
                      class="material-icons-outlined font-size-md primary--text"
                    >
                      delete
                    </i>
                  </v-btn>
                </template>
                <span class="font font-weight-medium font-size-sm"
                  >Delete Role</span
                >
              </v-tooltip>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <ConfirmationDialog
      :title="`Delete Role`"
      :state="dialog('alert')"
      :loading="isListLoading"
      @close="close"
      @save="deleteRole"
    />
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>

<script>
import PageTitle from "../../components/PageTitle";
import Spinner from "vue-simple-spinner";
import { mapGetters } from "vuex";
import DialogMixins from "../../mixins/DialogMixins";
import SnackBarMixins from "../../mixins/SnackBarMixins";

export default {
  name: "Roles",

  components: {
    PageTitle,
    ConfirmationDialog: () => import(`../sales/components/ConfirmationDialog`),
    SnackBar: () => import(`../../components/SnackBar`),
    spinner: Spinner
  },
  computed: {
    ...mapGetters({
      isListLoading: "getIsListLoading",
      roles: "acl/getRoleList",
      details: "acl/getRoleDetails"
    })
  },
  mixins: [DialogMixins, SnackBarMixins],
  data() {
    return {
      isPageLoading: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Actions", value: "actions" }
      ]
    };
  },

  watch: {
    inputs: {
      handler(value) {
        this.loadPermissionPerRoles(value);
      },
      deep: true
    }
  },
  methods: {
    deleteRole() {
      this.$store.dispatch("acl/deleteRole", { id: this.details?.id });
    }
  },
  created() {
    this.$store.dispatch("acl/listOfRoles");
    this.$store.dispatch("acl/listOfPermissions");
  }
};
</script>

<style scoped>
.table {
  max-width: 450px !important;
  margin-left: auto;
  margin-right: auto;
}

.h3 {
  text-align: center;
}
</style>
