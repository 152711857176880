var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.isPageLoading)?_c('v-row',[_c('spinner',{staticStyle:{"margin":"300px 500px"}})],1):_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"d-flex flex-row py-1"},[_c('PageTitle',{staticClass:" font font-weight-bold font-size-lg",attrs:{"text":"Access Control List"}})],1),_c('div',{staticClass:"d-flex flex-row mb-2"},[_c('v-btn',{staticClass:"font font-weight-medium font-size-md ",attrs:{"to":{
          name: 'master.roles.add',
          query: { action: 'add' }
        },"color":"primary"}},[_vm._v(" Add New Role "),_c('i',{staticClass:"material-icons-outlined"},[_vm._v("add")])])],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-data-table',{staticClass:" font font-weight-medium font-size-md box-shadow-light ma-1",attrs:{"headers":_vm.headers,"items":_vm.roles,"footer-props":{
            itemsPerPageOptions: [15, 20, 30]
          }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":{
                    name: 'master.roles.add',
                    query: { action: 'edit', id: item.id }
                  },"icon":"","id":item.id}},on),[_c('i',{staticClass:"material-icons-outlined font-size-md primary--text"},[_vm._v(" edit ")])])]}}],null,true)},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v("Edit Role")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","id":item.id},on:{"click":function($event){$event.stopPropagation();return _vm.open('alert', 'acl/details', item.id)}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-md primary--text"},[_vm._v(" delete ")])])]}}],null,true)},[_c('span',{staticClass:"font font-weight-medium font-size-sm"},[_vm._v("Delete Role")])])]}}])})],1)])]),_c('ConfirmationDialog',{attrs:{"title":"Delete Role","state":_vm.dialog('alert'),"loading":_vm.isListLoading},on:{"close":_vm.close,"save":_vm.deleteRole}}),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }